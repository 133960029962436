<template>
  <helpdesk :current-user="currentUser" />
</template>

<script>
import { mapGetters } from "vuex";
import Helpdesk from "@/views/commons/components/helpdesk/Helpdesk.vue";

export default {
  name: "FileMananger",
  components: { Helpdesk },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
};
</script>